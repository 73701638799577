var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"RaKQ2DCE8Oy7LUjF38g4L"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init } from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/tracing";

init({
  dsn: "https://244bace2a1954e768cd05e5429e529e0@o72953.ingest.sentry.io/4505100390760448",
  tracesSampleRate: 1.0,
  attachStacktrace: true,
  denyUrls: [/localhost/],
  ignoreErrors: [
    /loading chunk [\w+]+ failed/i,
    /hydration failed because the initial UI does not match what was rendered on the server/i,
    /There was an error while hydrating/i,
    /text content does not match server-rendered html/,
    "Text content does not match server-rendered HTML.",
    "There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.",
    "Hydration failed because the initial UI does not match what was rendered on the server.",
  ],
  integrations(integrations) {
    return [...integrations, new BrowserTracing()];
  },
});
